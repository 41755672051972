// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-statistics-index-jsx": () => import("./../../../src/pages/about/statistics/index.jsx" /* webpackChunkName: "component---src-pages-about-statistics-index-jsx" */),
  "component---src-pages-about-status-index-jsx": () => import("./../../../src/pages/about/status/index.jsx" /* webpackChunkName: "component---src-pages-about-status-index-jsx" */),
  "component---src-pages-about-updates-2014-03-06-fda-path-forward-for-open-data-and-next-gen-sequencing-index-jsx": () => import("./../../../src/pages/about/updates/2014_03_06_fda_path_forward_for_open_data_and_next_gen_sequencing/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2014-03-06-fda-path-forward-for-open-data-and-next-gen-sequencing-index-jsx" */),
  "component---src-pages-about-updates-2014-06-02-introducing-openfda-index-jsx": () => import("./../../../src/pages/about/updates/2014_06_02_introducing_openfda/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2014-06-02-introducing-openfda-index-jsx" */),
  "component---src-pages-about-updates-2014-06-02-openfda-opens-door-to-fda-public-data-index-jsx": () => import("./../../../src/pages/about/updates/2014_06_02_openfda_opens_door_to_fda_public_data/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2014-06-02-openfda-opens-door-to-fda-public-data-index-jsx" */),
  "component---src-pages-about-updates-2014-08-08-openfda-provides-ready-access-to-recall-data-index-jsx": () => import("./../../../src/pages/about/updates/2014_08_08_openfda_provides_ready_access_to_recall_data/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2014-08-08-openfda-provides-ready-access-to-recall-data-index-jsx" */),
  "component---src-pages-about-updates-2014-08-18-drug-product-labeling-index-jsx": () => import("./../../../src/pages/about/updates/2014_08_18_drug_product_labeling/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2014-08-18-drug-product-labeling-index-jsx" */),
  "component---src-pages-about-updates-2014-08-19-providing-easy-access-to-medical-device-reports-index-jsx": () => import("./../../../src/pages/about/updates/2014_08_19_providing_easy_access_to_medical_device_reports/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2014-08-19-providing-easy-access-to-medical-device-reports-index-jsx" */),
  "component---src-pages-about-updates-2015-05-11-an-open-challenge-to-tap-public-data-index-jsx": () => import("./../../../src/pages/about/updates/2015_05_11_an_open_challenge_to_tap_public_data/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2015-05-11-an-open-challenge-to-tap-public-data-index-jsx" */),
  "component---src-pages-about-updates-2015-07-20-first-year-in-perspective-index-jsx": () => import("./../../../src/pages/about/updates/2015_07_20_first_year_in_perspective/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2015-07-20-first-year-in-perspective-index-jsx" */),
  "component---src-pages-about-updates-2015-08-06-new-release-coming-soon-index-jsx": () => import("./../../../src/pages/about/updates/2015_08_06_new_release_coming_soon/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2015-08-06-new-release-coming-soon-index-jsx" */),
  "component---src-pages-about-updates-2015-08-31-medical-device-index-jsx": () => import("./../../../src/pages/about/updates/2015_08_31_medical_device/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2015-08-31-medical-device-index-jsx" */),
  "component---src-pages-about-updates-2015-12-23-direct-downloads-index-jsx": () => import("./../../../src/pages/about/updates/2015_12_23_direct_downloads/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2015-12-23-direct-downloads-index-jsx" */),
  "component---src-pages-about-updates-2017-08-30-openfda-limit-increased-index-jsx": () => import("./../../../src/pages/about/updates/2017_08_30_openfda_limit_increased/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2017-08-30-openfda-limit-increased-index-jsx" */),
  "component---src-pages-about-updates-2018-04-20-udi-dataset-updated-index-jsx": () => import("./../../../src/pages/about/updates/2018_04_20_udi_dataset_updated/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2018-04-20-udi-dataset-updated-index-jsx" */),
  "component---src-pages-about-updates-2018-06-22-new-look-same-great-api-index-jsx": () => import("./../../../src/pages/about/updates/2018_06_22_new_look_same_great_api/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2018-06-22-new-look-same-great-api-index-jsx" */),
  "component---src-pages-about-updates-2019-04-03-animal-drug-adverse-events-now-available-index-jsx": () => import("./../../../src/pages/about/updates/2019_04_03_Animal_Drug_Adverse_Events_Now_Available/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2019-04-03-animal-drug-adverse-events-now-available-index-jsx" */),
  "component---src-pages-about-updates-2020-06-04-covid-19-serological-testing-evaluations-now-available-index-jsx": () => import("./../../../src/pages/about/updates/2020_06_04_COVID-19_Serological_Testing_Evaluations_Now_Available/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2020-06-04-covid-19-serological-testing-evaluations-now-available-index-jsx" */),
  "component---src-pages-about-updates-2020-10-13-system-architecture-upgrade-index-jsx": () => import("./../../../src/pages/about/updates/2020_10_13_system_architecture_upgrade/index.jsx" /* webpackChunkName: "component---src-pages-about-updates-2020-10-13-system-architecture-upgrade-index-jsx" */),
  "component---src-pages-about-updates-index-js": () => import("./../../../src/pages/about/updates/index.js" /* webpackChunkName: "component---src-pages-about-updates-index-js" */),
  "component---src-pages-apis-anatomy-of-a-response-index-jsx": () => import("./../../../src/pages/apis/anatomy-of-a-response/index.jsx" /* webpackChunkName: "component---src-pages-apis-anatomy-of-a-response-index-jsx" */),
  "component---src-pages-apis-animalandveterinary-event-download-index-jsx": () => import("./../../../src/pages/apis/animalandveterinary/event/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-animalandveterinary-event-download-index-jsx" */),
  "component---src-pages-apis-animalandveterinary-event-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/animalandveterinary/event/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-animalandveterinary-event-example-api-queries-index-jsx" */),
  "component---src-pages-apis-animalandveterinary-event-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/animalandveterinary/event/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-animalandveterinary-event-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-animalandveterinary-event-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/animalandveterinary/event/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-animalandveterinary-event-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-animalandveterinary-event-index-jsx": () => import("./../../../src/pages/apis/animalandveterinary/event/index.jsx" /* webpackChunkName: "component---src-pages-apis-animalandveterinary-event-index-jsx" */),
  "component---src-pages-apis-animalandveterinary-event-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/animalandveterinary/event/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-animalandveterinary-event-searchable-fields-index-jsx" */),
  "component---src-pages-apis-animalandveterinary-event-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/animalandveterinary/event/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-animalandveterinary-event-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-animalandveterinary-index-jsx": () => import("./../../../src/pages/apis/animalandveterinary/index.jsx" /* webpackChunkName: "component---src-pages-apis-animalandveterinary-index-jsx" */),
  "component---src-pages-apis-api-endpoints-index-jsx": () => import("./../../../src/pages/apis/api_endpoints/index.jsx" /* webpackChunkName: "component---src-pages-apis-api-endpoints-index-jsx" */),
  "component---src-pages-apis-authentication-index-jsx": () => import("./../../../src/pages/apis/authentication/index.jsx" /* webpackChunkName: "component---src-pages-apis-authentication-index-jsx" */),
  "component---src-pages-apis-device-510-k-download-index-jsx": () => import("./../../../src/pages/apis/device/510k/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-510-k-download-index-jsx" */),
  "component---src-pages-apis-device-510-k-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/device/510k/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-510-k-example-api-queries-index-jsx" */),
  "component---src-pages-apis-device-510-k-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/device/510k/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-510-k-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-device-510-k-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/device/510k/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-510-k-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-device-510-k-index-jsx": () => import("./../../../src/pages/apis/device/510k/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-510-k-index-jsx" */),
  "component---src-pages-apis-device-510-k-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/device/510k/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-510-k-searchable-fields-index-jsx" */),
  "component---src-pages-apis-device-510-k-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/device/510k/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-510-k-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-device-classification-download-index-jsx": () => import("./../../../src/pages/apis/device/classification/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-classification-download-index-jsx" */),
  "component---src-pages-apis-device-classification-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/device/classification/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-classification-example-api-queries-index-jsx" */),
  "component---src-pages-apis-device-classification-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/device/classification/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-classification-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-device-classification-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/device/classification/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-classification-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-device-classification-index-jsx": () => import("./../../../src/pages/apis/device/classification/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-classification-index-jsx" */),
  "component---src-pages-apis-device-classification-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/device/classification/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-classification-searchable-fields-index-jsx" */),
  "component---src-pages-apis-device-classification-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/device/classification/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-classification-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-device-covid-19-serology-download-index-jsx": () => import("./../../../src/pages/apis/device/covid19serology/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-covid-19-serology-download-index-jsx" */),
  "component---src-pages-apis-device-covid-19-serology-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/device/covid19serology/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-covid-19-serology-example-api-queries-index-jsx" */),
  "component---src-pages-apis-device-covid-19-serology-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/device/covid19serology/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-covid-19-serology-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-device-covid-19-serology-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/device/covid19serology/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-covid-19-serology-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-device-covid-19-serology-index-jsx": () => import("./../../../src/pages/apis/device/covid19serology/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-covid-19-serology-index-jsx" */),
  "component---src-pages-apis-device-covid-19-serology-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/device/covid19serology/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-covid-19-serology-searchable-fields-index-jsx" */),
  "component---src-pages-apis-device-covid-19-serology-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/device/covid19serology/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-covid-19-serology-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-device-enforcement-download-index-jsx": () => import("./../../../src/pages/apis/device/enforcement/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-enforcement-download-index-jsx" */),
  "component---src-pages-apis-device-enforcement-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/device/enforcement/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-enforcement-example-api-queries-index-jsx" */),
  "component---src-pages-apis-device-enforcement-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/device/enforcement/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-enforcement-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-device-enforcement-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/device/enforcement/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-enforcement-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-device-enforcement-index-jsx": () => import("./../../../src/pages/apis/device/enforcement/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-enforcement-index-jsx" */),
  "component---src-pages-apis-device-enforcement-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/device/enforcement/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-enforcement-searchable-fields-index-jsx" */),
  "component---src-pages-apis-device-enforcement-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/device/enforcement/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-enforcement-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-device-event-download-index-jsx": () => import("./../../../src/pages/apis/device/event/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-event-download-index-jsx" */),
  "component---src-pages-apis-device-event-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/device/event/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-event-example-api-queries-index-jsx" */),
  "component---src-pages-apis-device-event-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/device/event/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-event-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-device-event-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/device/event/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-event-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-device-event-index-jsx": () => import("./../../../src/pages/apis/device/event/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-event-index-jsx" */),
  "component---src-pages-apis-device-event-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/device/event/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-event-searchable-fields-index-jsx" */),
  "component---src-pages-apis-device-event-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/device/event/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-event-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-device-index-jsx": () => import("./../../../src/pages/apis/device/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-index-jsx" */),
  "component---src-pages-apis-device-pma-download-index-jsx": () => import("./../../../src/pages/apis/device/pma/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-pma-download-index-jsx" */),
  "component---src-pages-apis-device-pma-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/device/pma/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-pma-example-api-queries-index-jsx" */),
  "component---src-pages-apis-device-pma-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/device/pma/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-pma-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-device-pma-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/device/pma/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-pma-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-device-pma-index-jsx": () => import("./../../../src/pages/apis/device/pma/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-pma-index-jsx" */),
  "component---src-pages-apis-device-pma-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/device/pma/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-pma-searchable-fields-index-jsx" */),
  "component---src-pages-apis-device-pma-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/device/pma/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-pma-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-device-recall-download-index-jsx": () => import("./../../../src/pages/apis/device/recall/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-recall-download-index-jsx" */),
  "component---src-pages-apis-device-recall-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/device/recall/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-recall-example-api-queries-index-jsx" */),
  "component---src-pages-apis-device-recall-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/device/recall/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-recall-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-device-recall-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/device/recall/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-recall-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-device-recall-index-jsx": () => import("./../../../src/pages/apis/device/recall/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-recall-index-jsx" */),
  "component---src-pages-apis-device-recall-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/device/recall/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-recall-searchable-fields-index-jsx" */),
  "component---src-pages-apis-device-recall-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/device/recall/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-recall-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-device-registrationlisting-download-index-jsx": () => import("./../../../src/pages/apis/device/registrationlisting/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-registrationlisting-download-index-jsx" */),
  "component---src-pages-apis-device-registrationlisting-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/device/registrationlisting/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-registrationlisting-example-api-queries-index-jsx" */),
  "component---src-pages-apis-device-registrationlisting-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/device/registrationlisting/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-registrationlisting-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-device-registrationlisting-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/device/registrationlisting/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-registrationlisting-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-device-registrationlisting-index-jsx": () => import("./../../../src/pages/apis/device/registrationlisting/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-registrationlisting-index-jsx" */),
  "component---src-pages-apis-device-registrationlisting-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/device/registrationlisting/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-registrationlisting-searchable-fields-index-jsx" */),
  "component---src-pages-apis-device-registrationlisting-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/device/registrationlisting/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-registrationlisting-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-device-udi-download-index-jsx": () => import("./../../../src/pages/apis/device/udi/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-udi-download-index-jsx" */),
  "component---src-pages-apis-device-udi-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/device/udi/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-udi-example-api-queries-index-jsx" */),
  "component---src-pages-apis-device-udi-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/device/udi/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-udi-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-device-udi-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/device/udi/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-udi-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-device-udi-index-jsx": () => import("./../../../src/pages/apis/device/udi/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-udi-index-jsx" */),
  "component---src-pages-apis-device-udi-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/device/udi/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-udi-searchable-fields-index-jsx" */),
  "component---src-pages-apis-device-udi-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/device/udi/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-device-udi-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-download-api-fields-index-jsx": () => import("./../../../src/pages/apis/download-api-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-download-api-fields-index-jsx" */),
  "component---src-pages-apis-drug-drugsfda-download-index-jsx": () => import("./../../../src/pages/apis/drug/drugsfda/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugsfda-download-index-jsx" */),
  "component---src-pages-apis-drug-drugsfda-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/drug/drugsfda/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugsfda-example-api-queries-index-jsx" */),
  "component---src-pages-apis-drug-drugsfda-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/drug/drugsfda/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugsfda-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-drug-drugsfda-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/drug/drugsfda/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugsfda-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-drug-drugsfda-index-jsx": () => import("./../../../src/pages/apis/drug/drugsfda/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugsfda-index-jsx" */),
  "component---src-pages-apis-drug-drugsfda-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/drug/drugsfda/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugsfda-searchable-fields-index-jsx" */),
  "component---src-pages-apis-drug-drugsfda-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/drug/drugsfda/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugsfda-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-drug-drugshortages-download-index-jsx": () => import("./../../../src/pages/apis/drug/drugshortages/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugshortages-download-index-jsx" */),
  "component---src-pages-apis-drug-drugshortages-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/drug/drugshortages/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugshortages-example-api-queries-index-jsx" */),
  "component---src-pages-apis-drug-drugshortages-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/drug/drugshortages/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugshortages-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-drug-drugshortages-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/drug/drugshortages/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugshortages-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-drug-drugshortages-index-jsx": () => import("./../../../src/pages/apis/drug/drugshortages/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugshortages-index-jsx" */),
  "component---src-pages-apis-drug-drugshortages-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/drug/drugshortages/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugshortages-searchable-fields-index-jsx" */),
  "component---src-pages-apis-drug-drugshortages-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/drug/drugshortages/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-drugshortages-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-drug-enforcement-download-index-jsx": () => import("./../../../src/pages/apis/drug/enforcement/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-enforcement-download-index-jsx" */),
  "component---src-pages-apis-drug-enforcement-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/drug/enforcement/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-enforcement-example-api-queries-index-jsx" */),
  "component---src-pages-apis-drug-enforcement-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/drug/enforcement/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-enforcement-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-drug-enforcement-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/drug/enforcement/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-enforcement-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-drug-enforcement-index-jsx": () => import("./../../../src/pages/apis/drug/enforcement/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-enforcement-index-jsx" */),
  "component---src-pages-apis-drug-enforcement-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/drug/enforcement/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-enforcement-searchable-fields-index-jsx" */),
  "component---src-pages-apis-drug-enforcement-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/drug/enforcement/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-enforcement-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-drug-event-download-index-jsx": () => import("./../../../src/pages/apis/drug/event/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-event-download-index-jsx" */),
  "component---src-pages-apis-drug-event-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/drug/event/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-event-example-api-queries-index-jsx" */),
  "component---src-pages-apis-drug-event-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/drug/event/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-event-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-drug-event-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/drug/event/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-event-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-drug-event-index-jsx": () => import("./../../../src/pages/apis/drug/event/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-event-index-jsx" */),
  "component---src-pages-apis-drug-event-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/drug/event/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-event-searchable-fields-index-jsx" */),
  "component---src-pages-apis-drug-event-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/drug/event/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-event-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-drug-index-jsx": () => import("./../../../src/pages/apis/drug/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-index-jsx" */),
  "component---src-pages-apis-drug-label-download-index-jsx": () => import("./../../../src/pages/apis/drug/label/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-label-download-index-jsx" */),
  "component---src-pages-apis-drug-label-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/drug/label/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-label-example-api-queries-index-jsx" */),
  "component---src-pages-apis-drug-label-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/drug/label/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-label-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-drug-label-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/drug/label/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-label-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-drug-label-index-jsx": () => import("./../../../src/pages/apis/drug/label/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-label-index-jsx" */),
  "component---src-pages-apis-drug-label-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/drug/label/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-label-searchable-fields-index-jsx" */),
  "component---src-pages-apis-drug-label-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/drug/label/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-label-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-drug-ndc-download-index-jsx": () => import("./../../../src/pages/apis/drug/ndc/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-ndc-download-index-jsx" */),
  "component---src-pages-apis-drug-ndc-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/drug/ndc/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-ndc-example-api-queries-index-jsx" */),
  "component---src-pages-apis-drug-ndc-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/drug/ndc/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-ndc-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-drug-ndc-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/drug/ndc/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-ndc-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-drug-ndc-index-jsx": () => import("./../../../src/pages/apis/drug/ndc/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-ndc-index-jsx" */),
  "component---src-pages-apis-drug-ndc-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/drug/ndc/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-ndc-searchable-fields-index-jsx" */),
  "component---src-pages-apis-drug-ndc-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/drug/ndc/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-drug-ndc-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-food-enforcement-download-index-jsx": () => import("./../../../src/pages/apis/food/enforcement/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-enforcement-download-index-jsx" */),
  "component---src-pages-apis-food-enforcement-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/food/enforcement/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-enforcement-example-api-queries-index-jsx" */),
  "component---src-pages-apis-food-enforcement-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/food/enforcement/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-enforcement-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-food-enforcement-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/food/enforcement/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-enforcement-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-food-enforcement-index-jsx": () => import("./../../../src/pages/apis/food/enforcement/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-enforcement-index-jsx" */),
  "component---src-pages-apis-food-enforcement-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/food/enforcement/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-enforcement-searchable-fields-index-jsx" */),
  "component---src-pages-apis-food-enforcement-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/food/enforcement/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-enforcement-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-food-event-download-index-jsx": () => import("./../../../src/pages/apis/food/event/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-event-download-index-jsx" */),
  "component---src-pages-apis-food-event-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/food/event/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-event-example-api-queries-index-jsx" */),
  "component---src-pages-apis-food-event-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/food/event/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-event-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-food-event-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/food/event/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-event-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-food-event-index-jsx": () => import("./../../../src/pages/apis/food/event/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-event-index-jsx" */),
  "component---src-pages-apis-food-event-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/food/event/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-event-searchable-fields-index-jsx" */),
  "component---src-pages-apis-food-event-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/food/event/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-event-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-food-index-jsx": () => import("./../../../src/pages/apis/food/index.jsx" /* webpackChunkName: "component---src-pages-apis-food-index-jsx" */),
  "component---src-pages-apis-index-jsx": () => import("./../../../src/pages/apis/index.jsx" /* webpackChunkName: "component---src-pages-apis-index-jsx" */),
  "component---src-pages-apis-missing-values-index-jsx": () => import("./../../../src/pages/apis/missing-values/index.jsx" /* webpackChunkName: "component---src-pages-apis-missing-values-index-jsx" */),
  "component---src-pages-apis-openfda-fields-index-jsx": () => import("./../../../src/pages/apis/openfda-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-openfda-fields-index-jsx" */),
  "component---src-pages-apis-other-historicaldocument-download-index-jsx": () => import("./../../../src/pages/apis/other/historicaldocument/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-historicaldocument-download-index-jsx" */),
  "component---src-pages-apis-other-historicaldocument-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/other/historicaldocument/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-historicaldocument-example-api-queries-index-jsx" */),
  "component---src-pages-apis-other-historicaldocument-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/other/historicaldocument/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-historicaldocument-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-other-historicaldocument-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/other/historicaldocument/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-historicaldocument-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-other-historicaldocument-index-jsx": () => import("./../../../src/pages/apis/other/historicaldocument/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-historicaldocument-index-jsx" */),
  "component---src-pages-apis-other-historicaldocument-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/other/historicaldocument/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-historicaldocument-searchable-fields-index-jsx" */),
  "component---src-pages-apis-other-historicaldocument-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/other/historicaldocument/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-historicaldocument-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-other-index-jsx": () => import("./../../../src/pages/apis/other/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-index-jsx" */),
  "component---src-pages-apis-other-nsde-download-index-jsx": () => import("./../../../src/pages/apis/other/nsde/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-nsde-download-index-jsx" */),
  "component---src-pages-apis-other-nsde-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/other/nsde/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-nsde-example-api-queries-index-jsx" */),
  "component---src-pages-apis-other-nsde-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/other/nsde/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-nsde-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-other-nsde-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/other/nsde/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-nsde-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-other-nsde-index-jsx": () => import("./../../../src/pages/apis/other/nsde/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-nsde-index-jsx" */),
  "component---src-pages-apis-other-nsde-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/other/nsde/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-nsde-searchable-fields-index-jsx" */),
  "component---src-pages-apis-other-nsde-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/other/nsde/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-nsde-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-other-substance-download-index-jsx": () => import("./../../../src/pages/apis/other/substance/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-substance-download-index-jsx" */),
  "component---src-pages-apis-other-substance-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/other/substance/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-substance-example-api-queries-index-jsx" */),
  "component---src-pages-apis-other-substance-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/other/substance/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-substance-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-other-substance-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/other/substance/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-substance-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-other-substance-index-jsx": () => import("./../../../src/pages/apis/other/substance/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-substance-index-jsx" */),
  "component---src-pages-apis-other-substance-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/other/substance/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-substance-searchable-fields-index-jsx" */),
  "component---src-pages-apis-other-substance-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/other/substance/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-substance-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-other-unii-download-index-jsx": () => import("./../../../src/pages/apis/other/unii/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-unii-download-index-jsx" */),
  "component---src-pages-apis-other-unii-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/other/unii/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-unii-example-api-queries-index-jsx" */),
  "component---src-pages-apis-other-unii-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/other/unii/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-unii-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-other-unii-index-jsx": () => import("./../../../src/pages/apis/other/unii/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-unii-index-jsx" */),
  "component---src-pages-apis-other-unii-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/other/unii/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-unii-searchable-fields-index-jsx" */),
  "component---src-pages-apis-other-unii-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/other/unii/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-other-unii-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-query-syntax-index-jsx": () => import("./../../../src/pages/apis/query-syntax/index.jsx" /* webpackChunkName: "component---src-pages-apis-query-syntax-index-jsx" */),
  "component---src-pages-apis-timeseries-index-jsx": () => import("./../../../src/pages/apis/timeseries/index.jsx" /* webpackChunkName: "component---src-pages-apis-timeseries-index-jsx" */),
  "component---src-pages-apis-tobacco-index-jsx": () => import("./../../../src/pages/apis/tobacco/index.jsx" /* webpackChunkName: "component---src-pages-apis-tobacco-index-jsx" */),
  "component---src-pages-apis-tobacco-problem-download-index-jsx": () => import("./../../../src/pages/apis/tobacco/problem/download/index.jsx" /* webpackChunkName: "component---src-pages-apis-tobacco-problem-download-index-jsx" */),
  "component---src-pages-apis-tobacco-problem-example-api-queries-index-jsx": () => import("./../../../src/pages/apis/tobacco/problem/example-api-queries/index.jsx" /* webpackChunkName: "component---src-pages-apis-tobacco-problem-example-api-queries-index-jsx" */),
  "component---src-pages-apis-tobacco-problem-explore-the-api-with-an-interactive-chart-index-jsx": () => import("./../../../src/pages/apis/tobacco/problem/explore-the-api-with-an-interactive-chart/index.jsx" /* webpackChunkName: "component---src-pages-apis-tobacco-problem-explore-the-api-with-an-interactive-chart-index-jsx" */),
  "component---src-pages-apis-tobacco-problem-how-to-use-the-endpoint-index-jsx": () => import("./../../../src/pages/apis/tobacco/problem/how-to-use-the-endpoint/index.jsx" /* webpackChunkName: "component---src-pages-apis-tobacco-problem-how-to-use-the-endpoint-index-jsx" */),
  "component---src-pages-apis-tobacco-problem-index-jsx": () => import("./../../../src/pages/apis/tobacco/problem/index.jsx" /* webpackChunkName: "component---src-pages-apis-tobacco-problem-index-jsx" */),
  "component---src-pages-apis-tobacco-problem-searchable-fields-index-jsx": () => import("./../../../src/pages/apis/tobacco/problem/searchable-fields/index.jsx" /* webpackChunkName: "component---src-pages-apis-tobacco-problem-searchable-fields-index-jsx" */),
  "component---src-pages-apis-tobacco-problem-understanding-the-api-results-index-jsx": () => import("./../../../src/pages/apis/tobacco/problem/understanding-the-api-results/index.jsx" /* webpackChunkName: "component---src-pages-apis-tobacco-problem-understanding-the-api-results-index-jsx" */),
  "component---src-pages-apis-try-the-api-index-jsx": () => import("./../../../src/pages/apis/try-the-api/index.jsx" /* webpackChunkName: "component---src-pages-apis-try-the-api-index-jsx" */),
  "component---src-pages-apis-wildcard-search-index-jsx": () => import("./../../../src/pages/apis/wildcard-search/index.jsx" /* webpackChunkName: "component---src-pages-apis-wildcard-search-index-jsx" */),
  "component---src-pages-community-index-jsx": () => import("./../../../src/pages/community/index.jsx" /* webpackChunkName: "component---src-pages-community-index-jsx" */),
  "component---src-pages-data-510-k-index-jsx": () => import("./../../../src/pages/data/510k/index.jsx" /* webpackChunkName: "component---src-pages-data-510-k-index-jsx" */),
  "component---src-pages-data-adae-index-jsx": () => import("./../../../src/pages/data/adae/index.jsx" /* webpackChunkName: "component---src-pages-data-adae-index-jsx" */),
  "component---src-pages-data-caers-index-jsx": () => import("./../../../src/pages/data/caers/index.jsx" /* webpackChunkName: "component---src-pages-data-caers-index-jsx" */),
  "component---src-pages-data-datadictionary-index-jsx": () => import("./../../../src/pages/data/datadictionary/index.jsx" /* webpackChunkName: "component---src-pages-data-datadictionary-index-jsx" */),
  "component---src-pages-data-device-recall-index-jsx": () => import("./../../../src/pages/data/device-recall/index.jsx" /* webpackChunkName: "component---src-pages-data-device-recall-index-jsx" */),
  "component---src-pages-data-downloads-index-jsx": () => import("./../../../src/pages/data/downloads/index.jsx" /* webpackChunkName: "component---src-pages-data-downloads-index-jsx" */),
  "component---src-pages-data-drugsfda-index-jsx": () => import("./../../../src/pages/data/drugsfda/index.jsx" /* webpackChunkName: "component---src-pages-data-drugsfda-index-jsx" */),
  "component---src-pages-data-drugshortages-index-jsx": () => import("./../../../src/pages/data/drugshortages/index.jsx" /* webpackChunkName: "component---src-pages-data-drugshortages-index-jsx" */),
  "component---src-pages-data-faers-index-jsx": () => import("./../../../src/pages/data/faers/index.jsx" /* webpackChunkName: "component---src-pages-data-faers-index-jsx" */),
  "component---src-pages-data-historicaldocument-index-jsx": () => import("./../../../src/pages/data/historicaldocument/index.jsx" /* webpackChunkName: "component---src-pages-data-historicaldocument-index-jsx" */),
  "component---src-pages-data-index-jsx": () => import("./../../../src/pages/data/index.jsx" /* webpackChunkName: "component---src-pages-data-index-jsx" */),
  "component---src-pages-data-maude-index-jsx": () => import("./../../../src/pages/data/maude/index.jsx" /* webpackChunkName: "component---src-pages-data-maude-index-jsx" */),
  "component---src-pages-data-ndc-index-jsx": () => import("./../../../src/pages/data/ndc/index.jsx" /* webpackChunkName: "component---src-pages-data-ndc-index-jsx" */),
  "component---src-pages-data-nsde-index-jsx": () => import("./../../../src/pages/data/nsde/index.jsx" /* webpackChunkName: "component---src-pages-data-nsde-index-jsx" */),
  "component---src-pages-data-pma-index-jsx": () => import("./../../../src/pages/data/pma/index.jsx" /* webpackChunkName: "component---src-pages-data-pma-index-jsx" */),
  "component---src-pages-data-product-classification-index-jsx": () => import("./../../../src/pages/data/product-classification/index.jsx" /* webpackChunkName: "component---src-pages-data-product-classification-index-jsx" */),
  "component---src-pages-data-registrationlisting-index-jsx": () => import("./../../../src/pages/data/registrationlisting/index.jsx" /* webpackChunkName: "component---src-pages-data-registrationlisting-index-jsx" */),
  "component---src-pages-data-res-index-jsx": () => import("./../../../src/pages/data/res/index.jsx" /* webpackChunkName: "component---src-pages-data-res-index-jsx" */),
  "component---src-pages-data-science-index-jsx": () => import("./../../../src/pages/data/science/index.jsx" /* webpackChunkName: "component---src-pages-data-science-index-jsx" */),
  "component---src-pages-data-spl-index-jsx": () => import("./../../../src/pages/data/spl/index.jsx" /* webpackChunkName: "component---src-pages-data-spl-index-jsx" */),
  "component---src-pages-data-tobaccoproblem-index-jsx": () => import("./../../../src/pages/data/tobaccoproblem/index.jsx" /* webpackChunkName: "component---src-pages-data-tobaccoproblem-index-jsx" */),
  "component---src-pages-data-unii-index-jsx": () => import("./../../../src/pages/data/unii/index.jsx" /* webpackChunkName: "component---src-pages-data-unii-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-docs-markdown-js": () => import("./../../../src/templates/docs-markdown.js" /* webpackChunkName: "component---src-templates-docs-markdown-js" */)
}

